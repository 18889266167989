import React from 'react'
import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import BydImage01 from '../../assets/vendorProduct/bydImage01_1.png'
import BydImage02 from '../../assets/vendorProduct/bydImage02.png'
import BydImage05 from '../../assets/vendorProduct/bydImage05.png'

import BydTableImage01 from '../../assets/vendorProduct/bydTableImage01.png';
import BydTableImage02 from '../../assets/vendorProduct/bydTableImage02.png';
import BydTableImage03 from '../../assets/vendorProduct/bydTableImage03.png';
import BydTableImage04 from '../../assets/vendorProduct/bydTableImage04.png';

import LeftImageTitleBox from '../../components/inner/venderProduct/LeftImageTitleBox'
import SIZE from '../../global/Size'
import COLOR from '../../global/Color'
import SubHeading from '../../components/inner/venderProduct/SubHeading';
import FullImage from '../../components/inner/venderProduct/FullImage';

const Byd = () => {
  return (
    <>
      <HeaderContainer>
        <HeaderTitle>BYD</HeaderTitle>
        <LeftImageTitleBox
          Image={BydImage01}
          Title={'BYD Pouch 3.2V 21.7Ah LiFePO4 Battery Cell'}>
          <span style={{fontSize: SIZE.LARGE}}>Lithium iron Phosphate Batterys</span>
          <div style={{width: '100%', height: 'auto', display: 'flex', paddingTop: 80, paddingBottom: 50}}>
            <div style={{width: '40%'}}>
              <span style={{fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY}}>Applications</span>
              <ul style={{lineHeight: 1.7}}>
                <li>Solar system</li>
                <li>Energy storage (ESS)</li>
                <li>Electric vehicles</li>
                <li>Golf carts</li>
                <li>RV</li>
              </ul>
            </div>

            <div>
              <span style={{fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY}}>Battery Features</span>
              <ul style={{lineHeight: 1.7}}>
                <li>High Energy Density</li>
                <li>Long Cycle Life</li>
                <li>Good performance at high/low temperature</li>
                <li>High-rate continuous discharge</li>
              </ul>
            </div>
          </div>
        </LeftImageTitleBox>

        <SubHeading>Parameters</SubHeading>
        <FullImage Item={BydTableImage01}/>

        <LeftImageTitleBox Image={BydImage02} Title={'BYD Pouch 3.2V 21.7Ah LiFePO4 Battery Cell'}>
          <span style={{fontSize: SIZE.LARGE}}>Lithium iron Phosphate Batterys</span>
          <div style={{width: '100%', height: 'auto', display: 'flex', paddingTop: 80, paddingBottom: 50}}>
            <div style={{width: '40%'}}>
              <span style={{fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY}}>Applications</span>
              <ul style={{lineHeight: 1.7}}>
                <li> DIY Lifepo4 battery Pack</li>
                <li>Solar system</li>
                <li>Energy storage (ESS)</li>
                <li>Electric vehicles</li>
                <li>Golf carts</li>
                <li>RV</li>
              </ul>
            </div>

            <div>
              <span style={{fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY}}>Battery Features</span>
              <ul style={{lineHeight: 1.7}}>
                <li>High Energy Density </li>
                <li>Long Cycle Life </li>
                <li>Good performance at high/low temperature </li>
                <li>High-rate continuous discharge </li>
              </ul>
            </div>
          </div>
        </LeftImageTitleBox>

        <SubHeading>Parameters</SubHeading>
        <FullImage Item={BydTableImage02}/>

        <LeftImageTitleBox
         Image={BydImage02} Title={'BYD FC4680 3.2V 15Ah LiFePO4 4680 Battery Cell'}>
          <span style={{fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY}}>Standardized</span>
            <ul style={{lineHeight: 1.7}}>
              <li>4680 industry-standard size, which facilitates standardized packassembly.</li>
              <li>Incorporate innovative Laser Cleaning technology and central-tabsdesign to reduce internal resistance</li>
            </ul>
          <span style={{fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY}}>High Safety</span>
            <ul style={{lineHeight: 1.7}}>
              <li>Passed the tests of Fire Burning, Nail Penatration, and Over-charging.</li>
              <li>No severe chemical reactions such as fire or explosion appeared duringThermal Runaway Test</li>
            </ul>
          <span style={{fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY}}>High Safety</span>
            <ul style={{lineHeight: 1.7}}>
              <li>6000 times at Room Temperature (25℃,0.5/0.5C,70% retention rate)</li>
              <li>2000 times at High Temperature (45℃,0.510.5C,70% retention rate)</li>
            </ul>          
        </LeftImageTitleBox>

        <p style={{fontSize: SIZE.MEDIUM, lineHeight: 1.7, paddingLeft: 10}}>
          1. Significantly increased battery power (6 times that of the 21700 battery), reduced battery costs, optimized heat dissipation performance, production efficiency, <br></br>
          and charging speed, and further improved energy density and cycle performance.
        </p>

        <p style={{fontSize: SIZE.MEDIUM, lineHeight: 1.7, paddingLeft: 10}}>
          2. The design of non-polar ears (full-polar ears) greatly shortens the distance of electronic movement, reduces internal battery resistance, <br></br>
          and significantly enhances the battery's charging and discharging capabilities.
        </p>

        <FullImage Item={BydTableImage03}/>

        <LeftImageTitleBox
          Image={BydImage05} Title={(<>
            BYD FC46120P 3.2V 25Ah 46120 Cyclindrical <br></br>
            LiFePO4 Battery Cell
          </>)}>
          <span style={{fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY}}>Standardized</span>
            <ul style={{lineHeight: 1.7}}>
              <li>4680 industry-standard size, which facilitates standardized packassembly.</li>
              <li>Incorporate innovative Laser Cleaning technology and central-tabsdesign to reduce internal resistance</li>
            </ul>
          <span style={{fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY}}>High Safety</span>
            <ul style={{lineHeight: 1.7}}>
              <li>Passed the tests of Fire Burning, Nail Penatration, and Over-charging.</li>
              <li>No severe chemical reactions such as fire or explosion appeared duringThermal Runaway Test</li>
            </ul>
          <span style={{fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY}}>High Safety</span>
            <ul style={{lineHeight: 1.7}}>
              <li>4000 times at Room Temperature (25℃,0.5/0.5C,70% retention rate)</li>
              <li>2000 times at High Temperature (45℃,0.510.5C,70% retention rate)</li>
            </ul>   
        </LeftImageTitleBox>

        <p style={{fontSize: SIZE.MEDIUM, lineHeight: 1.7, paddingLeft: 10}}>
          1. Significantly increased battery power (10 times that of the 21700 battery), reduced battery costs, optimized heat dissipation performance, production efficiency, <br></br>
          and charging speed, and further improved energy density and cycle performance;
        </p>

        <p style={{fontSize: SIZE.MEDIUM, lineHeight: 1.7, paddingLeft: 10}}>
          2. The design of non-polar ears (full-polar ears) greatly shortens the distance of electronic movement, reduces internal battery resistance, <br></br>
          and significantly enhances the battery's charging and discharging capabilities.
        </p>

        <FullImage Item={BydTableImage04} />

      </HeaderContainer>

    </>
  )
}

export default Byd