import React from 'react'
import COLOR from '../../global/Color'

import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContent from '../../components/header/HeaderContent'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import FullImage from '../../components/inner/venderProduct/FullImage'
import SubHeading from '../../components/inner/venderProduct/SubHeading'
import SubHeadingColor from '../../components/inner/venderProduct/SubHeadingColor'
import VendorTableBox from '../../components/inner/venderProduct/VendorTableBox'

import SierraWirelessImage01 from '../../assets/vendorProduct/sierraWirelessImage01.png'
import SierraWirelessImage02 from '../../assets/vendorProduct/sierraWirelessImage02.png'
import TableImage01 from '../../assets/vendorProduct/sierraWirelessTable01.png'
import TableImage02 from '../../assets/vendorProduct/sierraWirelessTable02.png'
import TableImage03 from '../../assets/vendorProduct/sierraWirelessTable03.png'

const SierraWireless = () => {

  const sierraWirelssData01 = [
    {
      image: TableImage01,
      title: 'One Global SIM',
      text: [
        'A portfolio of simple & reliable connectivity solutions,',
        'from one Global SIM to regional connectivity solutions for LPWA, 4G, 5G, LTE IOT deployments.'
      ]
    },
    {
      image: TableImage02,
      title: 'One loT Connectivity Partner',
      text: [
        'No matter where you are in your loT journey or technology evolution.',
        'Sierra has the proven experience to partner with and help you scale.'
      ]
    },
    {
      image: TableImage03,
      title: 'One Management Platform',
      text: [
        'Seamlessly manage your connectivity for business-critical assets with one platform built for lot.',
        ''
      ]
    }
  ]

  return (
  <>
    <HeaderContainer>
      <HeaderTitle>Sierra Wireless</HeaderTitle>
      <HeaderContent>
      <span style={{color: COLOR.MAGORELLEBLUE, fontWeight: 700}}>Sierra Wireless</span> <a style={{textDecoration: 'underline', color: COLOR.BLACK}} href='http://www.sierrawireless.com' target='_blank'>(http://www.sierrawireless.com)</a>는 캐나다에 본사를 둔 회사로 <br></br>
        Machine To Machine(M2M) 시장과 Mobile Broadband 시장에서 증명된 시장 선두 기업입니다. <br></br>
      </HeaderContent>

      <HeaderContentSmall>
        Sierra wireless는 무선 장치 및 어플리케이션에서 2G, 3G, 4G, 5G Modem, Router 그리고 Gateway, Software, 서비스까지 제공하여 <br></br>
        업계에서 가장 포괄적인 포트폴리오를 제공하고 있으며, 셀룰러 네트워크 통해 세상 속의 사람과 사물, 어플리케이션을 이어주고 있습니다. <br></br>
        무선 혁신은 Mobile 라이프 스타일과 M2M 통신에서 발전 되었으며, 오늘의 Sierra wireless의 제품은 Connecting virtually Anything to Anything을 표방하고 있습니다.
      </HeaderContentSmall>

      <FullImage Item={SierraWirelessImage01}/>

      <SubHeading>
        Product & Solution
      </SubHeading>

      <HeaderContentSmall>
        Sierra와 함께 시작하여 혁신적인 IoT 애플리케이션을 더 빠르고 저렴한 비용으로 출시하십시오. 운영 효율성을 개선하고 고객 결과를 최적화하며 <br></br>
        비즈니스를 위한 새로운 수익원을 창출하는 데 필요한 장치, 소프트웨어 및 서비스 솔루션을 제공하여 IoT를 최적화합니다.
      </HeaderContentSmall>

      <SubHeadingColor>
        Embedded Solutions and Networking Solutions
      </SubHeadingColor>

      <HeaderContentSmall>
        4G, 5G 및 LPWA 셀룰러 네트워크를 통해 자산과 인력을 클라우드에 연결하는 보안, 고성능 임베디드 모듈, 라우터 및 <br></br>
        게이트웨이의 광범위한 포트폴리오에서 선택이 가능합니다. <br></br>
        셀룰러, Wi-Fi, Bluetooth 및 GNSS 기술을 제품에 쉽게 포함하여 IoT 애플리케이션을 빠르게 시작할 수 있으며 추가 프로그래밍 없이 즉시개발 가능합니다.
      </HeaderContentSmall>

      <img src={SierraWirelessImage02} style={{width: '30%', paddingBottom: 50}}/>

      <SubHeadingColor>
        Connectivity Services and Managed IoT Solutions
      </SubHeadingColor>

      <HeaderContentSmall>
        모든 국가의 여러 네트워크에 대한 적용을 통해 글로벌 IoT 배포를 간소화하고 로컬 커버리지를 확대합니다. <br></br>
        단일 관리 플랫폼을 통해 안전하고 탄력적으로 적용이 가능합니다. <br></br>
        완전히 통합된 Turn-key 관리형 IoT 솔루션으로 IoT 솔루션을 신속하게 적용 가능합니다.
      </HeaderContentSmall>

      <VendorTableBox data={sierraWirelssData01}/>

    </HeaderContainer>

    <div style={{height: '80px'}} />
  </>
  )
}

export default SierraWireless