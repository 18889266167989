import React from 'react'
import DefaultContainer from '../components/container/DefaultContainer'
import MainNavigation from '../components/navigation/MainNaviagation'
import MainContainer from '../components/container/main/MainContainer'
import MainInnerText from '../components/inner/main/MainInnerText'
import MainInnerBtn from '../components/inner/main/MainInnerBtn'
import SecondContainer from '../components/container/main/SecondContainer'
import ThirdContainer from '../components/container/main/ThirdContainer'
import FifthContainer from '../components/container/main/FifthContainer'
import Footer from '../components/container/Footer'
import SecondInnerTitle from '../components/inner/main/second/SecondInnerTitle'
import SecondInnerBox from '../components/inner/main/second/SecondInnerBox'
import SecondInnerItem from '../components/inner/main/second/SecondInnerItem'

// / Vendor & Product 이미지 임포트
import Gyrfalcon from '../assets/main/ItemGyrfalconTechnology.png'
import Omnivision from '../assets/main/ItemOmnivision.png'
import Sierra from '../assets/main/ItemSierraWireless.png'
import Goodix from '../assets/main/ItemGoodix.png'
import Byd from '../assets/main/ItemByd.png'
import Catl from '../assets/main/ItemCatl.png'
import Synic from '../assets/main/ItemSynic.png'
import ThirdInnerTitle from '../components/inner/main/third/ThirdInnerTitle'
import ThirdInnerBox from '../components/inner/main/third/ThirdInnerBox'
import ThirdInnerItem from '../components/inner/main/third/ThirdInnerItem'

// / Application 이미지 임포트
import Automotive from '../assets/main/ItemAutomotive.png'
import BlackBox from '../assets/main/ItemBlackbox.png'
import Medical from '../assets/main/ItemMedical.png'
import Mobile from '../assets/main/ItemMobile.png'
import Security from '../assets/main/ItemSecurity.png'
import Telematics from '../assets/main/ItemTelematics.png'
import Dems from '../assets/main/ItemDems.png'
import FifthInnerBox from '../components/inner/main/fifth/FifthInnerBox'
import FifthInnerTitle from '../components/inner/main/fifth/FifthInnerTitle'
import FifthInnerItem from '../components/inner/main/fifth/FifthInnerItem'

// / Contact Us 이미지
import Adress from '../assets/main/ContactAdressIcon.png'
import Phone from '../assets/main/ContactPhoneIcon.png'
import Fax from '../assets/main/ContactFaxIcon.png'
import styled from 'styled-components'
import { Link } from 'react-router-dom'


const Main = () => {
  return (
    <DefaultContainer>
      
      {/* 첫 번째 칸 */}
      <MainContainer>
        <MainNavigation />
        <MainInnerText>Enhance your technology, <br/> plus your business</MainInnerText>
        <MainInnerBtn>Detail view</MainInnerBtn>
      </MainContainer>
      
      {/* 두 번째 칸 */}
      <SecondContainer>
        <SecondInnerTitle>Vendor & Product</SecondInnerTitle>
        <SecondInnerBox>
          <SecondInnerItem item={Goodix} link={'goodix'}/>
          <SecondInnerItem item={Omnivision} link={'omnivision'}/>
          <SecondInnerItem item={Sierra} link={'sierra wireless'}/>
          <SecondInnerItem item={Synic} link={'syncomm'}/>
          <SecondInnerItem item={Byd} link={'byd'}/>
          <SecondInnerItem item={Catl} link={'catl'}/>
        </SecondInnerBox>
      </SecondContainer>

      {/* 세 번째 칸 */}
      <ThirdContainer>
        <ThirdInnerTitle>Application</ThirdInnerTitle>
        <ThirdInnerBox>
          <ThirdInnerItem item={Automotive} text={'Automotive'} link={'automotive'}/>
          <ThirdInnerItem item={BlackBox} text={'BlackBox'} link={'blackbox'}/>
          <ThirdInnerItem item={Medical} text={'Medical'} link={'medical'}/>
          <ThirdInnerItem item={Mobile} text={'Mobile'} link={'mobile'}/>
          <ThirdInnerItem item={Security} text={'Security'} link={'security'}/>
          <ThirdInnerItem item={Telematics} text={'Telematics'} link={'telematics'}/>
          <ThirdInnerItem item={Dems} text={'Dems'} link={'dems'}/>
        </ThirdInnerBox>
      </ThirdContainer>

      {/* 네 번째 칸 */}
      {/* <FourthContainer>
        <FourthInnerTitle>News & Notice</FourthInnerTitle>
        
      </FourthContainer> */}

      {/* 다섯 번째 칸 */}
      <FifthContainer>
        <FifthInnerBox>
          <FifthInnerTitle>Contact Us</FifthInnerTitle>
          <FifthInnerItem Icon={Adress} title={'Adress'}>경기도 성남시 분당구 판교로 255번길 9-22 우림 W-City 708호</FifthInnerItem>
          <FifthInnerItem Icon={Phone} title={'Phone'}>031-628-8600</FifthInnerItem>
          <FifthInnerItem Icon={Fax} title={'Fax'}>031-628-8601</FifthInnerItem>
        </FifthInnerBox>
      </FifthContainer>

      <Footer />
      
    </DefaultContainer>
  )
}

const LinkButton = styled(Link)`
  width: 100%;
  height: 100%;

  /* 1300px 이상 1500px 미만 */
  @media (min-width: 1300px) and (max-width: 1499px) {
  }

  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
  }

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
  }

`

export default Main